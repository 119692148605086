
import { spliceMultiArrayIndex } from '@/assets/js/utils.js'
export const YueWenBook = [
  {
    SubCategoryName: '婚恋情缘',
    TopCategoryName: '女生',
    cbid: '22140631000615702',
    Form: '原创',
    level: 'C级',
    BookName: '闪婚厚爱：墨少宠妻成瘾',
    CategoryName: '现代言情',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '都市生活',
    TopCategoryName: '男生',
    cbid: '9744034003040703',
    Form: '原创',
    level: 'C级',
    BookName: '来自地狱的男人',
    CategoryName: '都市',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '豪门世家',
    TopCategoryName: '女生',
    cbid: '9984428803668403',
    Form: '原创',
    level: 'C级',
    BookName: '你是我的万有引力',
    CategoryName: '现代言情',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '穿越奇情',
    TopCategoryName: '女生',
    cbid: '22232014000363202',
    Form: '原创',
    level: 'B级',
    BookName: '神医灵泉：贵女弃妃',
    CategoryName: '古代言情',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '经商种田',
    TopCategoryName: '女生',
    cbid: '5216106804879703',
    Form: '原创',
    level: 'B级',
    BookName: '神医丑媳要逆袭',
    CategoryName: '古代言情',
    bookStatusName: '连载中'
  },
  {
    SubCategoryName: '豪门世家',
    TopCategoryName: '女生',
    cbid: '3364954400103103',
    Form: '原创',
    level: 'B级',
    BookName: '我和裴少闪婚了',
    CategoryName: '现代言情',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '都市生活',
    TopCategoryName: '男生',
    cbid: '22379443000737202',
    Form: '原创',
    level: 'C级',
    BookName: '超级战兵',
    CategoryName: '都市',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '搞笑吐槽',
    TopCategoryName: '男生',
    cbid: '7436012804111703',
    Form: '原创',
    level: 'C级',
    BookName: '我修的可能是假仙',
    CategoryName: '二次元',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '都市生活',
    TopCategoryName: '男生',
    cbid: '22122811000455102',
    Form: '原创',
    level: 'C级',
    BookName: '护身高手在校园',
    CategoryName: '都市',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '都市异能',
    TopCategoryName: '女生',
    cbid: '7778342304132101',
    Form: '原创',
    level: 'C级',
    BookName: '大神家那位又在闹海',
    CategoryName: '现代言情',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '异术超能',
    TopCategoryName: '男生',
    cbid: '10682638704887003',
    Form: '原创',
    level: 'C级',
    BookName: '我的女人你惹不起',
    CategoryName: '都市',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '豪门世家',
    TopCategoryName: '女生',
    cbid: '22187493000680502',
    Form: '原创',
    level: 'C级',
    BookName: '踏过星光说爱你',
    CategoryName: '现代言情',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '东方玄幻',
    TopCategoryName: '女生',
    cbid: '3737524604593201',
    Form: '原创',
    level: 'C级',
    BookName: '农妇灵泉',
    CategoryName: '玄幻言情',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '都市异能',
    TopCategoryName: '女生',
    cbid: '3737681304674601',
    Form: '原创',
    level: 'C级',
    BookName: '农门医香之田园致富',
    CategoryName: '现代言情',
    bookStatusName: '已完结'
  },
  {
    SubCategoryName: '都市生活',
    TopCategoryName: '男生',
    cbid: '22170593000665102',
    Form: '原创',
    level: 'C级',
    BookName: '纯情高手',
    CategoryName: '都市',
    bookStatusName: '已完结'
  }
]

export const YueWenChapterData = [
  {
    wordsCount: 1327,
    ccid: '108252060841052838',
    chapterName: '001 领证',
    id: 1,
    free: 1
  },
  {
    wordsCount: 1046,
    ccid: '108252060841069222',
    chapterName: '002 以后，没有关系',
    id: 2,
    free: 1
  },
  {
    wordsCount: 1046,
    ccid: '108252060841085607',
    chapterName: '003 各取所需',
    id: 3,
    free: 1
  },
  {
    wordsCount: 1407,
    ccid: '108252060841101990',
    chapterName: '004 放开她',
    id: 4,
    free: 1
  },
  {
    wordsCount: 1145,
    ccid: '108252060841118374',
    chapterName: '005 你也不怕？',
    id: 5,
    free: 1
  },
  {
    wordsCount: 1111,
    ccid: '108252060841134758',
    chapterName: '006 抢救',
    id: 6,
    free: 1
  },
  {
    wordsCount: 1029,
    ccid: '108252060841151142',
    chapterName: '007 想哭就哭出来',
    id: 7,
    free: 1
  },
  {
    wordsCount: 1011,
    ccid: '108252060841167526',
    chapterName: '008 全G城人都知道',
    id: 8,
    free: 1
  },
  {
    wordsCount: 1035,
    ccid: '108252060841183910',
    chapterName: '009 她是无辜的',
    id: 9,
    free: 1
  },
  {
    wordsCount: 1110,
    ccid: '108252060841200295',
    chapterName: '010 新婚之夜',
    id: 10,
    free: 1
  },
  {
    wordsCount: 1040,
    ccid: '108252060841216678',
    chapterName: '011 警告',
    id: 11,
    free: 0
  },
  {
    wordsCount: 1045,
    ccid: '108252060841233065',
    chapterName: '012 寻人',
    id: 12,
    free: 0
  },
  {
    wordsCount: 1013,
    ccid: '108252065136216743',
    chapterName: '013 质问',
    id: 13,
    free: 0
  },
  {
    wordsCount: 1031,
    ccid: '108252065136233126',
    chapterName: '014 你是我墨修尘的妻子',
    id: 14,
    free: 0
  },
  {
    wordsCount: 1049,
    ccid: '108252065136249510',
    chapterName: '015 不介意',
    id: 15,
    free: 0
  },
  {
    wordsCount: 1034,
    ccid: '108252065136265894',
    chapterName: '016 不愿意吗',
    id: 16,
    free: 0
  },
  {
    wordsCount: 1034,
    ccid: '108252065136282278',
    chapterName: '017 看戏',
    id: 17,
    free: 0
  },
  {
    wordsCount: 1012,
    ccid: '108252065136298662',
    chapterName: '018 你害怕我',
    id: 18,
    free: 0
  },
  {
    wordsCount: 1058,
    ccid: '108252065136315046',
    chapterName: '019 好东西',
    id: 19,
    free: 0
  },
  {
    wordsCount: 1011,
    ccid: '108252065136331430',
    chapterName: '020 算计',
    id: 20,
    free: 0
  },
  {
    wordsCount: 1164,
    ccid: '108252065136347814',
    chapterName: '021 穿给我看',
    id: 21,
    free: 0
  },
  {
    wordsCount: 790,
    ccid: '108252065136364198',
    chapterName: '022 和谁视频',
    id: 22,
    free: 0
  },
  {
    wordsCount: 919,
    ccid: '108252065136380583',
    chapterName: '023 一起睡',
    id: 23,
    free: 0
  },
  {
    wordsCount: 995,
    ccid: '108252065136396966',
    chapterName: '024 治病',
    id: 24,
    free: 0
  },
  {
    wordsCount: 1027,
    ccid: '108252065136413350',
    chapterName: '025 不像有病的样子',
    id: 25,
    free: 0
  },
  {
    wordsCount: 1001,
    ccid: '108252065136429735',
    chapterName: '026 爱慕',
    id: 26,
    free: 0
  },
  {
    wordsCount: 1032,
    ccid: '108252065136446118',
    chapterName: '027 不会对你怎样',
    id: 27,
    free: 0
  },
  {
    wordsCount: 997,
    ccid: '108252065136462502',
    chapterName: '028 出差',
    id: 28,
    free: 0
  },
  {
    wordsCount: 1037,
    ccid: '108252065136478886',
    chapterName: '029 找到的是',
    id: 29,
    free: 0
  },
  {
    wordsCount: 1049,
    ccid: '108252065136495270',
    chapterName: '030 相亲',
    id: 30,
    free: 0
  },
  {
    wordsCount: 1031,
    ccid: '108252065136511655',
    chapterName: '031 墨少喜欢吃什么',
    id: 31,
    free: 0
  },
  {
    wordsCount: 1068,
    ccid: '108252065136528038',
    chapterName: '032 她是我太太',
    id: 32,
    free: 0
  },
  {
    wordsCount: 1160,
    ccid: '108252065136544422',
    chapterName: '033 墨少送我的',
    id: 33,
    free: 0
  },
  {
    wordsCount: 972,
    ccid: '108252065136560806',
    chapterName: '034 言语暗示',
    id: 34,
    free: 0
  },
  {
    wordsCount: 1179,
    ccid: '108252065136577191',
    chapterName: '035 执念',
    id: 35,
    free: 0
  },
  {
    wordsCount: 1028,
    ccid: '108252065136593574',
    chapterName: '036 亲密',
    id: 36,
    free: 0
  },
  {
    wordsCount: 1045,
    ccid: '108252065136609958',
    chapterName: '037 只会喜欢你',
    id: 37,
    free: 0
  },
  {
    wordsCount: 1018,
    ccid: '108252065136626342',
    chapterName: '038 你不许喜欢别的男人',
    id: 38,
    free: 0
  },
  {
    wordsCount: 1131,
    ccid: '108252065136642726',
    chapterName: '039 明天到总公司上班',
    id: 39,
    free: 0
  },
  {
    wordsCount: 1018,
    ccid: '108252065136659110',
    chapterName: '040 除了温然，谁也不要',
    id: 40,
    free: 0
  },
  {
    wordsCount: 1022,
    ccid: '108252065136675495',
    chapterName: '041 墨家需要继承人',
    id: 41,
    free: 0
  },
  {
    wordsCount: 1052,
    ccid: '108252065136691878',
    chapterName: '042 别动！',
    id: 42,
    free: 0
  },
  {
    wordsCount: 1017,
    ccid: '108252065136708262',
    chapterName: '043 很漂亮',
    id: 43,
    free: 0
  },
  {
    wordsCount: 1051,
    ccid: '108252065136724646',
    chapterName: '044 不自知的宠溺',
    id: 44,
    free: 0
  },
  {
    wordsCount: 1036,
    ccid: '108252065136741030',
    chapterName: '045 再查一次',
    id: 45,
    free: 0
  },
  {
    wordsCount: 1043,
    ccid: '108252065136757415',
    chapterName: '046 你要习惯我',
    id: 46,
    free: 0
  },
  {
    wordsCount: 765,
    ccid: '108252065136773799',
    chapterName: '047 喜欢吗？',
    id: 47,
    free: 0
  },
  {
    wordsCount: 861,
    ccid: '108252065136790182',
    chapterName: '048 “墨修尘，我……”',
    id: 48,
    free: 0
  },
  {
    wordsCount: 1063,
    ccid: '108252065136806566',
    chapterName: '049 我用的温水',
    id: 49,
    free: 0
  },
  {
    wordsCount: 992,
    ccid: '108252065136822950',
    chapterName: '050 也许，他根本就没病',
    id: 50,
    free: 0
  },
  {
    wordsCount: 1014,
    ccid: '108252065136839334',
    chapterName: '051 帮你得到温然',
    id: 51,
    free: 0
  },
  {
    wordsCount: 1015,
    ccid: '108252065136855719',
    chapterName: '052 保证他查不出来',
    id: 52,
    free: 0
  },
  {
    wordsCount: 1027,
    ccid: '108252065136872102',
    chapterName: '053 墨修尘的细心体贴',
    id: 53,
    free: 0
  },
  {
    wordsCount: 1035,
    ccid: '108252065136888488',
    chapterName: '054 我一定努力工作',
    id: 54,
    free: 0
  },
  {
    wordsCount: 1015,
    ccid: '108252065136904870',
    chapterName: '055 有更重要的事要查',
    id: 55,
    free: 0
  },
  {
    wordsCount: 1018,
    ccid: '108252065136921254',
    chapterName: '056 为了墨修尘？',
    id: 56,
    free: 0
  },
  {
    wordsCount: 1031,
    ccid: '108252065136937638',
    chapterName: '057 永远爱你',
    id: 57,
    free: 0
  },
  {
    wordsCount: 1045,
    ccid: '108252065136954022',
    chapterName: '058 巧遇',
    id: 58,
    free: 0
  },
  {
    wordsCount: 1044,
    ccid: '108252065136970407',
    chapterName: '059 这外套穿上',
    id: 59,
    free: 0
  },
  {
    wordsCount: 2084,
    ccid: '108252065136986790',
    chapterName: '060 解释',
    id: 60,
    free: 0
  }
]

export const mockRegionData = [
  {
    id: 1,
    label: '中国',
    children: [
      {
        id: '101',
        label: '北京'
      },
      {
        id: '102',
        label: '上海'
      },
      {
        id: '103',
        label: '天津'
      },
      {
        id: '104',
        label: '重庆'
      },
      {
        id: '105',
        label: '广东'
      },
      {
        id: '106',
        label: '福建'
      },
      {
        id: '107',
        label: '海南'
      },
      {
        id: '108',
        label: '安徽'
      },
      {
        id: '109',
        label: '贵州'
      },
      {
        id: '110',
        label: '甘肃'
      },
      {
        id: '111',
        label: '广西'
      },
      {
        id: '112',
        label: '河北'
      },
      {
        id: '113',
        label: '河南'
      },
      {
        id: '114',
        label: '黑龙江'
      },
      {
        id: '115',
        label: '湖北'
      },
      {
        id: '116',
        label: '湖南'
      },
      {
        id: '117',
        label: '吉林'
      },
      {
        id: '118',
        label: '江苏'
      },
      {
        id: '119',
        label: '江西'
      },
      {
        id: '120',
        label: '辽宁'
      },
      {
        id: '121',
        label: '内蒙古'
      },
      {
        id: '122',
        label: '宁夏'
      },
      {
        id: '123',
        label: '青海'
      },
      {
        id: '124',
        label: '山东'
      },
      {
        id: '125',
        label: '山西'
      },
      {
        id: '126',
        label: '陕西'
      },
      {
        id: '127',
        label: '四川'
      },
      {
        id: '128',
        label: '西藏'
      },
      {
        id: '129',
        label: '新疆'
      },
      {
        id: '130',
        label: '云南'
      },
      {
        id: '131',
        label: '浙江'
      },
      {
        id: '132',
        label: '香港'
      },
      {
        id: '133',
        label: '台湾'
      },
      {
        id: '134',
        label: '澳门'
      }
    ]
  }
]

let ageList = [
  {
    label: '14岁',
    value: 14
  }
]
const len = 66 - 18 + 1

Array.from({ length: len }, (_, index) => {
  ageList.push({
    label: index === len - 1 ? `${index + 18}岁以上` : `${index + 18}岁`,
    value: index + 18
  })
})
const indexList = []
ageList.forEach((item, index) => {
  if (['15岁', '16岁', '17岁'].includes(item.label)) {
    indexList.push(index)
  }
})
ageList = spliceMultiArrayIndex(ageList, indexList)

export default ageList

const emojiList = [
  {
    cid: 1,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_0.png',
    parentId: 0,
    parentName: '[微笑]',
    type: 3
  },
  {
    cid: 2,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_1.png',
    parentId: 0,
    parentName: '[撇嘴]',
    type: 3
  },
  {
    cid: 3,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_2.png',
    parentId: 0,
    parentName: '[色]',
    type: 3
  },
  {
    cid: 4,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_3.png',
    parentId: 0,
    parentName: '[发呆]',
    type: 3
  },
  {
    cid: 5,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_5.png',
    parentId: 0,
    parentName: '[流泪]',
    type: 3
  },
  {
    cid: 6,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_6.png',
    parentId: 0,
    parentName: '[害羞]',
    type: 3
  },
  {
    cid: 7,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_8.png',
    parentId: 0,
    parentName: '[睡]',
    type: 3
  },
  {
    cid: 8,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_9.png',
    parentId: 0,
    parentName: '[大哭]',
    type: 3
  },
  {
    cid: 9,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_10.png',
    parentId: 0,
    parentName: '[尴尬]',
    type: 3
  },
  {
    cid: 10,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_11.png',
    parentId: 0,
    parentName: '[发怒]',
    type: 3
  },
  {
    cid: 11,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_12.png',
    parentId: 0,
    parentName: '[调皮]',
    type: 3
  },
  {
    cid: 12,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_13.png',
    parentId: 0,
    parentName: '[呲牙]',
    type: 3
  },
  {
    cid: 13,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_14.png',
    parentId: 0,
    parentName: '[惊讶]',
    type: 3
  },
  {
    cid: 14,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_15.png',
    parentId: 0,
    parentName: '[难过]',
    type: 3
  },
  {
    cid: 15,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_17.png',
    parentId: 0,
    parentName: '[冷汗]',
    type: 3
  },
  {
    cid: 16,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_18.png',
    parentId: 0,
    parentName: '[抓狂]',
    type: 3
  },
  {
    cid: 17,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_20.png',
    parentId: 0,
    parentName: '[偷笑]',
    type: 3
  },
  {
    cid: 18,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_21.png',
    parentId: 0,
    parentName: '[愉快]',
    type: 3
  },
  {
    cid: 19,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_22.png',
    parentId: 0,
    parentName: '[白眼]',
    type: 3
  },
  {
    cid: 20,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_23.png',
    parentId: 0,
    parentName: '[傲慢]',
    type: 3
  },
  {
    cid: 21,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_26.png',
    parentId: 0,
    parentName: '[惊恐]',
    type: 3
  },
  {
    cid: 22,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_27.png',
    parentId: 0,
    parentName: '[流汗]',
    type: 3
  },
  {
    cid: 23,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_28.png',
    parentId: 0,
    parentName: '[憨笑]',
    type: 3
  },
  {
    cid: 24,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_30.png',
    parentId: 0,
    parentName: '[奋斗]',
    type: 3
  },
  {
    cid: 25,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_32.png',
    parentId: 0,
    parentName: '[疑问]',
    type: 3
  },
  {
    cid: 26,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_34.png',
    parentId: 0,
    parentName: '[晕]',
    type: 3
  },
  {
    cid: 27,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_36.png',
    parentId: 0,
    parentName: '[衰]',
    type: 3
  },
  {
    cid: 28,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_38.png',
    parentId: 0,
    parentName: '[敲打]',
    type: 3
  },
  {
    cid: 29,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_39.png',
    parentId: 0,
    parentName: '[再见]',
    type: 3
  },
  {
    cid: 30,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_40.png',
    parentId: 0,
    parentName: '[擦汗]',
    type: 3
  },
  {
    cid: 31,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_42.png',
    parentId: 0,
    parentName: '[鼓掌]',
    type: 3
  },
  {
    cid: 32,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_44.png',
    parentId: 0,
    parentName: '[坏笑]',
    type: 3
  },
  {
    cid: 33,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_45.png',
    parentId: 0,
    parentName: '[左哼哼]',
    type: 3
  },
  {
    cid: 34,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_46.png',
    parentId: 0,
    parentName: '[右哼哼]',
    type: 3
  },
  {
    cid: 35,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_47.png',
    parentId: 0,
    parentName: '[哈欠]',
    type: 3
  },
  {
    cid: 36,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_49.png',
    parentId: 0,
    parentName: '[委屈]',
    type: 3
  },
  {
    cid: 37,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_50.png',
    parentId: 0,
    parentName: '[快哭了]',
    type: 3
  },
  {
    cid: 38,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_51.png',
    parentId: 0,
    parentName: '[阴险]',
    type: 3
  },
  {
    cid: 39,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_52.png',
    parentId: 0,
    parentName: '[亲亲]',
    type: 3
  },
  {
    cid: 40,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_54.png',
    parentId: 0,
    parentName: '[可怜]',
    type: 3
  },
  {
    cid: 41,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_56.png',
    parentId: 0,
    parentName: '[西瓜]',
    type: 3
  },
  {
    cid: 42,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_60.png',
    parentId: 0,
    parentName: '[咖啡]',
    type: 3
  },
  {
    cid: 43,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_62.png',
    parentId: 0,
    parentName: '[猪头]',
    type: 3
  },
  {
    cid: 44,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_63.png',
    parentId: 0,
    parentName: '[玫瑰]',
    type: 3
  },
  {
    cid: 45,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_65.png',
    parentId: 0,
    parentName: '[嘴唇]',
    type: 3
  },
  {
    cid: 46,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_66.png',
    parentId: 0,
    parentName: '[爱心]',
    type: 3
  },
  {
    cid: 47,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_68.png',
    parentId: 0,
    parentName: '[蛋糕]',
    type: 3
  },
  {
    cid: 48,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_75.png',
    parentId: 0,
    parentName: '[月亮]',
    type: 3
  },
  {
    cid: 49,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_76.png',
    parentId: 0,
    parentName: '[太阳]',
    type: 3
  },
  {
    cid: 50,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_78.png',
    parentId: 0,
    parentName: '[拥抱]',
    type: 3
  },
  {
    cid: 51,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_79.png',
    parentId: 0,
    parentName: '[强]',
    type: 3
  },
  {
    cid: 52,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_81.png',
    parentId: 0,
    parentName: '[握手]',
    type: 3
  },
  {
    cid: 53,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_82.png',
    parentId: 0,
    parentName: '[胜利]',
    type: 3
  },
  {
    cid: 54,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_83.png',
    parentId: 0,
    parentName: '[抱拳]',
    type: 3
  },
  {
    cid: 55,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_84.png',
    parentId: 0,
    parentName: '[勾引]',
    type: 3
  },
  {
    cid: 56,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_85.png',
    parentId: 0,
    parentName: '[拳头]',
    type: 3
  },
  {
    cid: 57,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_89.png',
    parentId: 0,
    parentName: '[OK]',
    type: 3
  },
  {
    cid: 58,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_92.png',
    parentId: 0,
    parentName: '[跳跳]',
    type: 3
  },
  {
    cid: 59,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_93.png',
    parentId: 0,
    parentName: '[发抖]',
    type: 3
  },
  {
    cid: 60,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_94.png',
    parentId: 0,
    parentName: '[怄火]',
    type: 3
  },
  {
    cid: 61,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/smiley_95.png',
    parentId: 0,
    parentName: '[转圈]',
    type: 3
  },
  {
    cid: 62,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_04.png',
    parentId: 0,
    parentName: '[嘿哈]',
    type: 3
  },
  {
    cid: 63,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_05.png',
    parentId: 0,
    parentName: '[捂脸]',
    type: 3
  },
  {
    cid: 64,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_02.png',
    parentId: 0,
    parentName: '[奸笑]',
    type: 3
  },
  {
    cid: 65,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_06.png',
    parentId: 0,
    parentName: '[机智]',
    type: 3
  },
  {
    cid: 66,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_12.png',
    parentId: 0,
    parentName: '[皱眉]',
    type: 3
  },
  {
    cid: 67,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_11.png',
    parentId: 0,
    parentName: '[耶]',
    type: 3
  },
  {
    cid: 68,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_20.png',
    parentId: 0,
    parentName: '[加油]',
    type: 3
  },
  {
    cid: 69,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_21.png',
    parentId: 0,
    parentName: '[汗]',
    type: 3
  },
  {
    cid: 70,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_22.png',
    parentId: 0,
    parentName: '[天啊]',
    type: 3
  },
  {
    cid: 71,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_23.png',
    parentId: 0,
    parentName: '[社会社会]',
    type: 3
  },
  {
    cid: 72,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_24.png',
    parentId: 0,
    parentName: '[旺柴]',
    type: 3
  },
  {
    cid: 73,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_25.png',
    parentId: 0,
    parentName: '[好的]',
    type: 3
  },
  {
    cid: 74,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_26.png',
    parentId: 0,
    parentName: '[加油加油]',
    type: 3
  },
  {
    cid: 75,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_27.png',
    parentId: 0,
    parentName: '[哇]',
    type: 3
  },
  {
    cid: 76,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_09.png',
    parentId: 0,
    parentName: '[红包]',
    type: 3
  },
  {
    cid: 77,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_16.png',
    parentId: 0,
    parentName: '[發]',
    type: 3
  },
  {
    cid: 78,
    name: 'https://wxa.wxs.qq.com/wxad-design/emojis/2_15.png',
    parentId: 0,
    parentName: '[福]',
    type: 3
  }
]

export { emojiList }
